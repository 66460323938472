<template>
  <div>
    <b-card>
      <table class="w-100">
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="CheckIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Nama</span>
          </th>
          <td class="pb-50 text-capitalize">
            {{ resultData.name }}
          </td>
        </tr>
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="CalendarIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Tanggal Mulai</span>
          </th>
          <td class="pb-50">
            {{ resultData.start_date }}
          </td>
        </tr>
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="CalendarIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Tanggal Akhir</span>
          </th>
          <td class="pb-50">
            {{ resultData.end_date }}
          </td>
        </tr>
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="DollarSignIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Minimum Total Transaksi</span>
          </th>
          <td class="pb-50 text-capitalize">
            <span v-if="resultData.minimum_transaction_total !== null">
              {{ resultData.minimum_transaction_total | formatAmount }}
            </span>
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="ClockIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Minimum Total Transaksi</span>
          </th>
          <td class="pb-50 text-capitalize">
            {{ resultData.minimum_transaction_time + 'x' }}
          </td>
        </tr>
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="AlertTriangleIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Harus Talenta Jagoan</span>
          </th>
          <td class="pb-50 text-capitalize">
            <span
              class="badge text-capitalize badge-pill"
              :class="
                resultData.must_order_with_talenta_jagoan == true
                  ? 'text-success'
                  : 'text-danger'
              "
            >
              <feather-icon
                :icon="resultData.must_order_with_talenta_jagoan == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                class="mr-75"
              />
            </span>
          </td>
        </tr>
        <tr>
          <th class="pb-50 d-lg-flex">
            <feather-icon
              icon="AwardIcon"
              class="mr-75 d-none d-lg-flex"
            />
            <span class="font-weight-bold">Reward</span>
          </th>
          <td class="pb-50 text-capitalize">
            {{ resultData.coupon_reward && resultData.coupon_reward.purpose_label !== null ? resultData.coupon_reward.purpose_label : '-' }}
          </td>
        </tr>
      </table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
  },
  data() {
    return {
      isLoadingComponent: true,
      users: [],
      resultData: {},
    }
  },
  methods: {
    goToDetail(url, id) {
      this.$router.push({ name: url, params: { id } })
    },
  },
}
</script>

<style lang="scss">

</style>
