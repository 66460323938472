<template>
  <div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Order</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in resultData.data"
            v-else
            :key="item.id"
          >
            <td>
              <a
                v-if="item.user"
                class="text-primary"
                @click="goToDetail('order.show', item.order.uuid)"
              >
                {{ item.order && item.order.invoice_number !== null ? item.order.invoice_number : '-' }}
              </a>
            </td>
            <td>
              <a
                v-if="item.user"
                class="text-primary"
                @click="goToDetail('users.show', item.user.uuid)"
              >
                {{ item.user && item.user.name !== null ? item.user.name : '-' }}<br><small>{{ item.user && item.user.email !== null ? item.user.email : '-' }}</small>
              </a>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="resultData.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="resultData"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    isLoading: {
      type: Boolean,
    },
    getData: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
  },
  data() {
    return {
      isLoadingComponent: true,
      resultData: {},
    }
  },
  methods: {
    goToDetail(url, id) {
      this.$router.push({ name: url, params: { id } })
    },
  },
}
</script>

<style lang="scss">

</style>
