<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <DetailMissions :result="result" />
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card v-if="result">
          <ListPencapaian
            :is-loading="isLoading"
            :result="resultListMission"
            :get-data="getListMission"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import DetailMissions from '@/components/Missions/DetailMissions.vue'
import ListPencapaian from '@/components/Missions/ListPencapaian.vue'

export default {
  title() {
    return 'Detail Misi'
  },
  components: {
    BCard,
    BRow,
    BCol,
    DetailMissions,
    ListPencapaian,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      result: {},
      isLoading: true,
      resultListMission: {},
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getListMission(page = 1) {
      this.isLoading = true
      this.$http.get(`/admin/mission-achievements?mission_uuid=${this.id}`,
        {
          params: {
            page,
          },
        })
        .then(response => {
          this.resultListMission = response.data.data
          this.isLoading = false
        })
    },
    getData() {
      this.isLoading = true
      this.$http.get(`/admin/missions/${this.id}`)
        .then(response => {
          this.result = response.data.data

          if (this.result) {
            this.getListMission()
          }
          this.isLoading = false
        })
    },
  },
}
</script>
